import Switch from '@mui/material/Switch'

// Americanas React context
import {
  useMaterialUIController,
  setDarkMode,
} from '@context'
import { Box, Typography } from '@mui/material'

function Configurator() {
  const [controller, dispatch] = useMaterialUIController()

  const { darkMode } = controller


  const handleDarkMode = () => setDarkMode(dispatch, !darkMode)

  return (
    <Box display="flex" flexDirection="column" gap={2} >
      <Switch checked={darkMode} onChange={handleDarkMode} />
      <Typography variant='h6' color="inherit">Dark Mode</Typography>
    </Box>
  )
}

export default Configurator
