import { DashboardPage } from '@pages/home'
import { OcurrencesPage } from '@pages/ocurrences'
import { WrapperComponent } from '@containers/wrapper'
import { Routes, Route, Navigate } from 'react-router-dom'

function Router() {
  return (
    <Routes>
      <Route element={<WrapperComponent />}>
        <Route path="/dashboard" >
          <Route path="" element={<DashboardPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/ocurrences" >
          <Route path="" element={<OcurrencesPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Route>
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}

export default Router
