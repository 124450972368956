import { DashboardProps } from './types'
import { DashboardPageContext } from './context'
import ReportsTable from '@containers/Reports/Table'
import { useContextSelector } from 'use-context-selector'
import ReportsBarChart from '@containers/ReportsBarChart'
import ReportsPieChart from '@containers/Reports/PieChart'
import ReportsWordCloudChart from '@containers/Reports/WordCloudChart'
import ReportsHorizontalBarChart from '@containers/Reports/HorizontalBar'

export function DashboardPageView({ labels, title, description, chartType }: DashboardProps) {
  const data = useContextSelector(DashboardPageContext, (s) => s.data)
  const error = useContextSelector(DashboardPageContext, (s) => s.error)
  const loading = useContextSelector(DashboardPageContext, (s) => s.loading)
  const handleLoadData = useContextSelector(DashboardPageContext, (s) => s.handleLoadData)

  if (chartType === 'pie') {
    return <ReportsPieChart
      color="white"
      error={error}
      dashboard={data}
      loading={loading}
      labels={labels}
      title={title}
      description={description}
      handleLoadData={handleLoadData}
    />
  }

  if (chartType === 'wordCloud') {
    return <ReportsWordCloudChart
      color="white"
      error={error}
      title={title}
      labels={labels}
      dashboard={data}
      loading={loading}
      description={description}
      handleLoadData={handleLoadData}
    />
  }


  if (chartType === 'table') {
    return <ReportsTable
      color="white"
      error={error}
      title={title}
      labels={labels}
      dashboard={data}
      loading={loading}
      description={description}
      handleLoadData={handleLoadData}
    />
  }


  if (chartType === 'horizontalBar') {
    return <ReportsHorizontalBarChart
      color="white"
      error={error}
      title={title}
      labels={labels}
      dashboard={data}
      loading={loading}
      description={description}
      handleLoadData={handleLoadData}
    />
  }


  return (
    <ReportsBarChart
      color="white"
      error={error}
      dashboard={data}
      loading={loading}
      labels={labels}
      title={title}
      description={description}
      handleLoadData={handleLoadData}
    />
  )
}
