/**
=========================================================
* Americanas React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Americanas React base styles
import colors from "@assets/theme/base/colors";

const { text } = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

export default formLabel;
