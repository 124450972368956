import { useState } from "react"
import { Box } from "@mui/system"
import { TypeOptions } from "@pages/home/types"
import { useContextSelector } from "use-context-selector"
import { DashboardPageContext } from "@pages/home/context"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"

export const Filters = () => {
    const type = useContextSelector(DashboardPageContext, s => s.type)
    const filter = useContextSelector(DashboardPageContext, s => s.filter)
    const reload = useContextSelector(DashboardPageContext, s => s.reload)
    const setType = useContextSelector(DashboardPageContext, s => s.setType)
    const setFilter = useContextSelector(DashboardPageContext, s => s.setFilter)
    const setReload = useContextSelector(DashboardPageContext, s => s.setReload)

    const [currentType, setCurrentType] = useState<string>(type)
    const [currentFilter, setCurrentFilter] = useState<string>(filter)
    const [currentReload, setCurrentReload] = useState<string>(reload)

    const handleChangeReload = (e: SelectChangeEvent) => {
        setReload(e.target.value)
        setCurrentReload(e.target.value)
    }

    const handleChangeFilter = (e: SelectChangeEvent) => {
        setFilter(e.target.value)
        setCurrentFilter(e.target.value)
    }

    const handleChangeType = (e: SelectChangeEvent) => {
        setType(e.target.value)
        setCurrentType(e.target.value)
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mt: 2, mb: 3 }}>
            <FormControl >
                <InputLabel id="date-label">Período</InputLabel>
                <Select
                    labelId="date-label"
                    id="date-select"
                    value={currentFilter}
                    label="Período"
                    onChange={handleChangeFilter}
                    sx={{ height: 50 }}
                >
                    <MenuItem value="24">24 horas</MenuItem>
                    <MenuItem value="7">7 dias</MenuItem>
                    <MenuItem value="15">15 dias</MenuItem>
                    <MenuItem value="30">30 dias</MenuItem>
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="type-label">Tipos de dado</InputLabel>
                <Select
                    labelId="type-label"
                    id="type-select"
                    value={currentType}
                    label="Tipo"
                    onChange={handleChangeType}
                    sx={{ height: 50 }}
                >
                    <MenuItem value={TypeOptions.hour}>Ocorrências por qualificação por hora</MenuItem>
                    <MenuItem value={TypeOptions.days}>Ocorrências por dia</MenuItem>
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="reload-label">Recarregamento automatico</InputLabel>
                <Select
                    labelId="reload-label"
                    id="reload-select"
                    value={currentReload}
                    label="Recarregamento automatido"
                    onChange={handleChangeReload}
                    sx={{ height: 50, minWidth: 200 }}
                >
                    <MenuItem value="5">5 minutos</MenuItem>
                    <MenuItem value="15">15 minutos</MenuItem>
                    <MenuItem value="30">30 minutos</MenuItem>
                    <MenuItem value="60">1 hora</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}