/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { Filter } from '@type/dashboard'
import { DashboardPageContext } from './context'
import { DashboardPageControllerProps } from './types'
import { DashboardApi } from '@services/api/dashboard_api'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

export const DashboardPageController = (
  props: DashboardPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [value, forceUpdate] = useReducer(x => x + 1, 0)
  const [reload, setReload] = useState<string>("60")
  const [filter, setFilter] = useState<string>("24")
  const [loading, setLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<Filter[]>([])
  const [type, setType] = useState<string>('OCORRENCIASPORQUALIFICACAOPORHORA')

  const handleLoadFilters = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<DashboardApi>(DashboardApi)
      .list_filters()
      .then((filters: Filter[]) => {
        setFilters(filters)
      })
  }, [])

  useEffect(() => {
    const time = parseInt(reload) * 60000 // 1 minuto

    if (time > 0) {
      setInterval(() => {
        forceUpdate()
      }, time)
    }
  }, [reload])

  useEffect(() => {
    handleLoadFilters()
  }, [])

  const state = useMemo(
    () => ({
      loading,
      value,
      filters,
      filter,
      setFilter,
      type,
      setType,
      reload,
      setReload
    }),
    [loading, value, filters, filter, setFilter, type, setType, reload, setReload]
  )

  return (
    <DashboardPageContext.Provider value={state}>
      {props.children}
    </DashboardPageContext.Provider>
  )
}
