import { Grid } from '@mui/material'
import { TypeOptions } from './types'
import { Labels } from './components/Labels'
import { Filters } from './components/Filters'
import { DashboardPageContext } from './context'
import { Dashboard } from '@containers/Dashboard'
import { useContextSelector } from 'use-context-selector'

export function DashboardPageView() {
  const value = useContextSelector(DashboardPageContext, (s) => s.value)
  const type = useContextSelector(DashboardPageContext, (s) => s.type)
  const filter = useContextSelector(DashboardPageContext, (s) => s.filter)
  const filters = useContextSelector(DashboardPageContext, (s) => s.filters)

  const getFilter = (name: string) => {
    const available = filters.filter(f => f.nome.toUpperCase().indexOf(name.toUpperCase()) !== -1)
    return available.find(a => a.nome.indexOf(filter) !== -1)
  }

  const barLabels = type === TypeOptions.hour && filter === "24" ? <Labels /> : undefined

  return (
    <>
      <Filters />

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Dashboard
            type={type}
            value={value}
            chartType="bar"
            title="Todos os sócios"
            filter={getFilter("todos")}
            labels={barLabels}
            description="Analise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            type="OCORRENCIASPORQUALIFICACAOPORSERVICO"
            value={value}
            chartType="pie"
            title="Redes sociais"
            filter={getFilter("todos")}
            description="Analise da distribuição de menções nas redes"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            type="PUBLICADORESMAISINFLUENTES"
            value={value}
            title="Publicadores mais influentes"
            chartType="table"
            filter={getFilter("todos")}
            description="Analise dos publicadores mais influentes baseado no alcance atingido"
          />
        </Grid>


        <Grid item xs={12} md={4}>
          <Dashboard
            value={value}
            type="TAGSMAISUTILIZADAS"
            chartType="horizontalBar"
            title="Tags mais utilizadas"
            filter={getFilter("todos")}
            description="Distribuição das menções em temas por alcance"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            type="NUVEMTERMOSMAISCITADOS"
            value={value}
            chartType="wordCloud"
            title="Termos mais utilizados"
            filter={getFilter("todos")}
            description="Palavras mais utilizados ao falar dos sócios"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dashboard
            value={value}
            type={type}
            title="Lemann"
            chartType="bar"
            filter={getFilter("lemann")}
            labels={barLabels}
            description="Analise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Dashboard
            value={value}
            type={type}
            title="Telles"
            chartType="bar"
            filter={getFilter("telles")}
            labels={barLabels}
            description="Analise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Dashboard
            value={value}
            type={type}
            title="Sicupira"
            chartType="bar"
            filter={getFilter("sicupira")}
            labels={barLabels}
            description="Analise do volume de menções em redes sociais, blogs e portais no período"
          />
        </Grid>

      </Grid>
    </>
  )
}
